const firebaseConfig = {
    apiKey: "AIzaSyAE7sVd5e9IFm-JVOrJcJJ55uvAcJiYSu8",
    authDomain: "blocs-dbbbc.firebaseapp.com",
    databaseURL: "https://blocs-dbbbc-default-rtdb.firebaseio.com",
    projectId: "blocs-dbbbc",
    storageBucket: "blocs-dbbbc.appspot.com",
    messagingSenderId: "233943518658",
    appId: "1:233943518658:web:8c97ae603811c2afd49a5b",
    measurementId: "G-KDFMHGCYPC"
};

export default firebaseConfig