import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import firebaseConfig from './env/firebase.js';
import { initializeApp } from "firebase/app";
import { getFirestore,doc, collection, addDoc, updateDoc, getDoc, query, where, getDocs, increment, setDoc } from "firebase/firestore"; 

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


import { Player, Controls } from '@lottiefiles/react-lottie-player';




import Blocc from './components/blocc';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

let window_ref = window.location.toString()
let blocc_id = window_ref.split('/').pop().split('#')[0]

/* eslint-disable */
var DateTime = luxon.DateTime;
var d = DateTime.now().toString() // moment().format()
var today = d.substring(0,10)  // moment().format(); 

//let blocc_id = 'z20EPJ9YlfNxFtP1Jc7n'
console.log(DateTime.now())

/* eslint-disable */
$(window).scroll(function(){
  var position = $(this).scrollTop();
  
  //console.log('position ==> ',position)
  if(position >= 45) {
    $(".topblock").addClass('animate__fadeOut')
    $(".top-cover").removeClass("d-none").addClass("animate__fadeInDown")
  } 
  else {
    $(".topblock").removeClass("animate__fadeOut").addClass('animate__fadeIn')
    //$(".top-cover").removeClass("animate__fadeInDown").addClass("animate__fadeOutUp d-none")
    $(".top-cover").addClass('d-none')
  }
})


function App() {
  const [bgColor, isColor] = useState(null)
  const [profileimage, isProfileImage] = useState('https://thumbs.dreamstime.com/b/creative-vector-illustration-of-default-avatar-profile-placeholder-isolated-on-background-art-design-grey-photo-blank-template-mo-107388687.jpg')
  const [descr, isDescr] = useState('...')
  const [hasbackground,this_hasBackgroud] = useState(false)
  const [backgroundValue,this_backgroundValue] = useState(null)
  const [structure, isStructure] = useState([])
  const [transparent, isTransparent] = useState(null)
  const [maincolor, isMainColor] = useState(null)

  const [facebook,isFacebook] = useState(null)
  const [twitter,isTwitter] = useState(null)
  const [tiktok,isTiktok] = useState(null)
  const [instagram,isInstagram] = useState(null)

  const [showLinks, isShowLinks] = useState(false)
  const [loaded, isLoaded] = useState(false)
  const [dataloaded, isDataLoaded] = useState(false)

  const [bloccid, isBloccId] = useState(null)

  const [showFullImage, isFullImage] = useState(false)
  const  [currentimage,setImage] = useState(null)

  //const [animationParent] = useAutoAnimate()

  let updateStats = async (id,user_id) => {
    console.log('update blocc with id ===> ',id)
    const userRef = doc(db, "users", user_id);
    await updateDoc(userRef, {
      allviews: increment(1),
      
    });

    const bloccRef = doc(db, "bloccs", id);
    await updateDoc(bloccRef, {
      'stats.views': increment(1),
      
    });

    console.log('stats updated!')
  }

  let updateStatsDetailed = async (id) => {
    const bloccRef = doc(db, "bloccs", id, 'insights', today );
    const alltimeRef = doc(db, "bloccs", id, 'insights', 'alltime');
    const date = DateTime.now().c
    const week = DateTime.local(date.year,date.month,date.day).weekNumber
    //alert(week)
    try{
      await updateDoc(bloccRef, {
        'views': increment(1),  
      })
      await updateDoc(alltimeRef, {
        'views': increment(1),  
      })
      console.log('stats updated!')
    }
    catch(e){
      console.log(e)
      await setDoc(bloccRef, {
        views: 1,
        shares: 0,
        id: today,
        time:{
          week: 50,
          month: date.month,
          year: date.year,
          day:date.day,
        },
        links: {},
        traffic_from:{
          android: 0,
          ios: 0,
          web: 0
        },
        bloccID: id
        
      })
      await setDoc(alltimeRef, {
        views: 1,
        shares: 0,
        links: {},
        traffic_from:{
          android: 0,
          ios: 0,
          web: 0
        },
        bloccID: id
        
      })
    }
    
  }


  let updateShares = async () => {
    const bloccRef = doc(db, "bloccs", bloccid, 'insights', today );
    const alltimeRef = doc(db, "bloccs", bloccid, 'insights', 'alltime');

    try{
        await updateDoc(bloccRef, {
            "shares": increment(1),  
        })
        await updateDoc(alltimeRef, {
            "shares": increment(1)
        })
        console.log('stats updated!')
    }
    catch(e){
        console.log(e)
    }
    
  }

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
  }

  let share = () => {
    if (navigator.share) {
        navigator.share({
          title: 'Share this Goblocc',
          url: 'https://goblocc.com/' + bloccid
        }).then(async () => {
          console.log('update blocc with id ==> ',bloccid)
          const bloccRef = doc(db, "bloccs", bloccid);
          updateShares()
          console.log('goblocc shared')
        }).catch(console.error);
    } 
    else {
      alert("This device can't share!")
    }
  }

  let loadBlocc = async () => {
    const q = query(collection(db, "bloccs"), where("link", "==", blocc_id));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      
 
      if (doc.exists()) {
        let this_id = doc.id
        let data = doc.data()
        let newstructure = data.structure
        //console.log(data)
        // isProfileLink(data.link),
        isBloccId(doc.id)
        sessionStorage.setItem('blocc_id',doc.id)

        isFacebook(data.socials.facebook)
        isTiktok(data.socials.tiktok)
        isTwitter(data.socials.twitter)
        isInstagram(data.socials.instagram)

        isColor(data?.bgColor)
        isTransparent(data?.hasTransparency.state)
        isMainColor(data?.hasMainColor.color)
        this_hasBackgroud(data?.hasBackground.state)
        this_backgroundValue(data?.hasBackground.value)
        isProfileImage(data?.cover);
        isDescr(data?.descr)
       
       
        //isStack(data?.stack)
        
        //alert(transparent)
        if(data?.stack){
          let arr = data.structure
          let newArr = []
          let stack = []

          console.log(arr)
  
          for(var i = 0; i < arr.length; i++){
            if(arr[i].type !== 'imagecard'){
              newArr.push(arr[i])
            }
            else{
              //console.log(arr[i].type + ' <=========> ' + arr[i + 1].type)
              if(arr[i + 1] !== undefined && arr[i].type == 'imagecard' && arr[i + 1].type !== 'imagecard' && stack.length == 0){
                newArr.push(arr[i])
              }
              else if(arr[i + 1] !== undefined && arr[i].type == 'imagecard' && arr[i + 1]?.type == 'imagecard'){
                stack.push(arr[i])
              }
              else{
                console.log('hellooooooooooo')
                stack.push(arr[i])
                newArr.push(stack)
                stack = []
              }
            }
          }
  
          //console.log('=======new Arr =========>',newArr)
          isStructure(newArr)

          updateStats(this_id,data.owner_id)
          updateStatsDetailed(this_id)
        }
        else isStructure(data.structure)

        isDataLoaded(true)
      }
    });
    
   
  }

  useEffect(() => {
    loadBlocc()
  },[])

  const render = structure.map((data,index,elements) => {
    if(!data.length || data.length == undefined){
      return <Blocc key={data.id} id={data.id} Type={data.type} Link={data.link} Title={data.title} Transparent={transparent} backgroundColor={data.color} TextColor={data.color_type} Description={data.description} Source={data.source} MainColor={maincolor} onClick={() =>{
        alert('hi')
        isFullImage(true)
        setImage(data.source)
        isShowLinks(true)
      }}/>
    } 
    
    else{
      const renderstack = data.map((data,index,elements) => {
        return <div className='col-12' style={{marginRight:'5%'}}>
          <Blocc key={data.id} id={data.id} Type={data.type} Link={data.link} Title={data.title} Description={data.description} backgroundColor={data.color} Source={data.source} 
              onClick={() =>{
                alert('hi')
              isFullImage(true)
              setImage(data.source)
              
            }} 
          /></div>
      })
      return <div className='d-flex row-flex stacked' style={{marginBottom:15}}>{renderstack}</div>
    }

    
  })

  const renderAnchors = structure.map((data,index,elements) => {
    //console.log(data)
    if(data.type === 'anchorcard'){
      return (
        <a className='nostyle' href={`#${data.id}`}>
          <div className='anchorBtn' onClick={() => {setTimeout(() => {isShowLinks(false)}, 450)}}>{data.title}</div>
        </a>
      ) 
      }
  })

  
  if(!loaded){
    return (
      <div className="fullscreen transition" style={{zIndex:99,backgroundColor:dataloaded && !hasbackground? bgColor:'#212121',justifyContent:'center',alignItems:'center'}}>
        
        <div className='centered' style={{width:370,height:355}}>
          <Player
            autoplay
            speed={1.65}
            onEvent={event => {
              if (event === 'complete') isLoaded(true) // check event type and do something
            }}
    
            src="https://assets8.lottiefiles.com/packages/lf20_poqmycwy.json"
            style={{ height: '300px', width: '300px',margin:'auto',zIndex:999}}
          />
        </div>
       
        <div className='centered'  style={{width:50,height:45}}>
          <div style={{display:'grid',zIndex:2}}>
            <img width={50} src='./logo.png'/>
            <img width={50} src='./logotext.png' style={{marginTop:10}}/>
          </div>
        </div>
        
          <div style={{zIndex:0,opacity:hasbackground && dataloaded? 0.5:0}}>
            <div className='dim animate__animated animate__fadeIn' style={{zIndex:1}}/>
            <img src={backgroundValue? backgroundValue:'./bgplaceholder.png'} className="bgcoverPre animate__animated animate__fadeIn delay" alt="logo" />
          </div>   
        
        
      </div>
      
    )
  }

  return (
    <div className="App" style={{backgroundColor:hasbackground? 'transparent': bgColor}}>
      {showLinks &&
        <div className="fullscreen">

          <span className="material-icons menu" style={{position:'absolute',top: '2%',backgroundColor:'black',borderRadius:100,padding:5}} onClick={() => isShowLinks(false)}>close</span>
          <span style={{position:'absolute',top: '2%',left:'2%',backgroundColor:'black',borderRadius:100,padding:5,fontSize:28,width:40,height:40}} class="menu material-icons animate__animated animate__fadeIn" onClick={() => share()}>share</span>
        
          
          <div style={{width:345,margin:'auto',height:'100%',position:'relative',marginTop:115}}>
            
            <div className='animate__animated animate__fadeIn' style={{justifyContent:'center',alignItems:'center',marginBottom:15,flexDirection:'row',borderBottomColor: 'white',borderBottomWidth:1}}>
              {facebook &&
                <i onClick={() => window.open(facebook, '_blank')} class="custom-icons inmenu fa-brands fa-facebook-f"></i>
              }
              {twitter &&
                <i onClick={() => window.open(twitter, '_blank')} class="custom-icons inmenu fa-brands fa-twitter"></i>
              }
              {instagram &&
                <i onClick={() => window.open(instagram, '_blank')} class="custom-icons inmenu fa-brands fa-instagram"></i>
              }
              {tiktok &&
                <i onClick={() => window.open(tiktok, '_blank')} class="custom-icons inmenu fa-brands fa-tiktok"></i>
              }
              
            </div> 

            <hr style={{borderWidth:2,color:'white'}} />
            
            <h4 className='text-white' style={{marginBottom:25}}>Jump To</h4>
            <>{renderAnchors}</>
          </div>
          
        </div>
      }

      {showFullImage &&
        <div className="fullscreen">

          <span className="material-icons menu" style={{position:'absolute',top: '2%',backgroundColor:'black',borderRadius:100,padding:5}} onClick={() => isShowLinks(false)}>close</span>
          
        
          
          <div style={{width:345,margin:'auto',height:'100%',position:'relative',marginTop:115}}>
            
           
            
            <img src={currentimage} className='fullImage' />
          </div>
          
        </div>
      }
    

      <div className='top-cover d-flex animate__animated d-none'>
        <img src={profileimage} className="blocc-cover-reduced topz animate__animated animate__fadeIn" alt="logo" />
        <p className={`descrText-reduced animate__animated animate__fadeIn topz ${hasbackground? 'text-white':''}`}>{descr}</p>

        <span className="material-icons menu" onClick={() => {
          console.log('clicked!!!')
          isShowLinks(true)
        }}>menu</span>
      </div>
      
      {hasbackground &&
        <>
          <div className='dim'></div>
          <img src={backgroundValue} className="bgcover" alt="logo" />
        </>   
      }
      <header className="App-header" style={{zIndex:99}}>
        
        <span id='shareIcon' class="material-icons animate__animated animate__fadeIn" 
          onClick={() => share()}
        >share</span>

        <img src={profileimage} className="blocc-cover topz animate__animated animate__fadeIn topblock" alt="logo" />
        <p className={`descrText animate__animated animate__fadeIn topz topblock ${hasbackground? 'text-white':''}`}>{descr}</p>

        <div className='topblock animate__animated animate__fadeIn' style={{justifyContent:'center',alignItems:'center',marginBottom:15,zIndex:99999}}>
          {facebook &&
            <i class="custom-icons fa-brands fa-facebook-f" onClick={() => window.open(facebook, '_blank')} />
          }
          
          {twitter &&
            <i class="custom-icons fa-brands fa-twitter" onClick={() => window.open(twitter, '_blank')}/>
          }

          {instagram &&
            <i class="custom-icons fa-brands fa-instagram" onClick={() => window.open(instagram, '_blank')}/>
          }

          {tiktok &&
            <i class="custom-icons fa-brands fa-tiktok" onClick={() => window.open(tiktok, '_blank')}/>
          }
        </div>
      

        <div className={` animate__animated animate__fadeIn structureContainer topz ${hasbackground? 'text-white':''}`}>{render}</div>
      </header>
    </div>
  );
}

export default App;
